<template>
  <div class="chartwrapper" :class="chartClasses">
    <div :class="headerClasses">
      <slot name="header"></slot>
      <template v-if="this.$slots.chartlinks">
        <slot name="chartlinks"></slot>
      </template>
    </div>
    <div :class="chartsClass" v-if="this.$slots.charts">
      <slot name="charts"></slot>
    </div>
    <div v-else-if="Object.keys(this.chartOptions).length">
      <Chart :options="chartOptions" />
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
import Chart from '@/components/Chart.vue'
export default {
  data () {
    return {}
  },
  components: {
    Chart
  },
  props: {
    chartOptions: {
      required: false,
      type: Object,
      default: () => ({})
    },
    headerClasses: {
      required: false,
      type: String,
      default: ''
    },
    chartClasses: {
      required: false,
      type: String,
      default: ''
    },
    chartsClass: {
      required: false,
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/_custom-variables.scss';
@import '@/styles/_custom-mixins.scss';
.highcharts-title tspan {
  font-family: $secondary-font;
  line-height: 21px;
  font-size: 16px;

  color: #6D6D55;
}
#mt-shares, #mt-inquiries {
  .highcharts-title tspan {
    font-family: "Georgia";
    line-height: 24px;
    font-size: 23px;

    color: #6D6D55;
  }
  .highcharts-subtitle {
    font-family: $primary_font !important;
    line-height: 18px !important;
    font-size: 15px !important;
    margin-top: 10px !important;
    color: #838382 !important;
  }
}

@media only screen and (min-width : 768px) {
  .highcharts-title tspan {
    line-height: 28px;
    font-size: 22px;
  }
  #mt-shares, #mt-inquiries {
    .highcharts-title tspan {
      line-height: 29px;
      font-size: 32px;

      color: #3A3A3A;
    }
  }
}
</style>
