<template>
  <div class="supplySideTrends">
    <Section title="" subtitle="" iconClass="trends-icon" colClass="supply-side-trends">
      <template slot="content">
        <b-container fluid>
          <b-row>
            <GraphLoader v-if="loadingMarketTrends"/>
            <b-col v-else cols="24" sm="8" class="p-3" v-for="(chart) in this.charts.components" :key="chart.id">
              <div class="chart-container">
                <MonthChart
                    :type="chart.type"
                    :overrides="chartOverrides[chart.id]"
                    :ref="chart.id"
                    :title="chart.title"
                  />
                </div>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </Section>
  </div>
</template>



<script>
import Section from '@/components/Section'
import Charts from '@/components/ChartsSections'
import MonthChart from '@/components/MonthChart.vue'
import GraphLoader from '@/components/loaders/Graph'

import {mapGetters, mapState} from 'vuex'

export default {
  name: 'supply-side-trends',
  components: {
    Section,
    Charts,
    MonthChart,
    GraphLoader
  },
  data: function () {
    return {

    };
  },
  computed: {
    ...mapGetters('properties', {
      //supplySideTrends: 'supplySideTrends',
      marketTrends: 'marketTrends',
      medianPriceValues: 'medianPriceValues',
      marketTrendsMonths: 'marketTrendsMonths',
      housesSoldValues: 'housesSoldValues',
      priceSqftValues: 'priceSqftValues'
    }),
    ...mapState('properties', {
      //loadingSupplySideTrends: 'loadingSupplySideTrends',
      loadingMarketTrends: 'loadingMarketTrends'
    }),
    charts: function() {
      var _this = this
      return {
        components: [
          {
            id: 'supplySideChart-medSold',
            name: 'Med Sold $',
            title: 'Median Sold Price',
            type: 'spline',
            recent: _this.mostRecent_medPrice
          },
          {
            id: 'supplySideChart-numHomes',
            name: '# Homes',
            title: 'Number of Homes Sold',
            type: 'spline',
            recent: _this.mostRecent_homesSold
          },
          {
            id: 'supplySideChart-avgSqft',
            name: 'Avg $ / sqft',
            title: 'Average Sale Price per Square Foot',
            type: 'spline',
            recent: _this.mostRecent_priceSqft
          }
        ]
      }
    },
    mostRecent_homesSold: function () {
      if(this.marketTrends.length) {
        return this.marketTrends[this.marketTrends.length - 1].homes_sold;
      }
      return '--';
    },
    mostRecent_priceSqft: function () {
      if(this.marketTrends.length) {
        return this.$options.filters.formatPrice(this.marketTrends[this.marketTrends.length - 1].avg_price_per_sqft);
      }
      return '--';
    },
    mostRecent_medPrice: function () {
      if(this.marketTrends.length) {
        return this.$options.filters.formatPrice(this.marketTrends[this.marketTrends.length - 1].median_sale_price);
      }
      return '--';
    },
    mostRecent_monthYear: function () {
      return this.$options.filters.formatMonth(this.marketTrends[this.marketTrends.length - 1].month)
        + " " + this.marketTrends[this.marketTrends.length - 1].year;
    },
    xAxisValues: function () {
      return this.marketTrends
        .map(a => (this.$options.filters.formatMonth3Letter(a.month) + " `" + (a.year % 100)));
    },

    chartOverrides: function () {
      var _this = this;
      return {
        'supplySideChart-medSold':
        {
          chart: {
            events: {
              load: function() {
                this.tooltip.refresh(this.series[0].data[this.series[0].data.length - 1]);
              }
            },
            spacingRight:50
          },
          xAxis: {
            categories: this.xAxisValues
          },
          yAxis: {
            title: {
              text: 'Price'
            }
          },
          legend: {enabled: false},
          tooltip: {
            backgroundColor: null,
            borderWidth: 0,
            shadow: false,
            useHTML: true,
            formatter: function () {
              return "<span style='color:white'>"+_this.$options.filters.formatPrice(this.y)+"</span>";
            },
            positioner: function(boxWidth, boxHeight, point) {
              // Set up the variables
              var pointX = point.plotX,
                  pointY = point.plotY

              return {
                x: pointX + 20,
                y: pointY
              };
            }
          },
          series: [
            {
              name: 'MedianSoldPrice',
              data: this.medianPriceValues,
              color: '#999',
              marker: {
                enabled: true,
                symbol: 'circle' // Can do a custom url here if needed...
              }
            }
          ]
        },
        'supplySideChart-numHomes':
        {
          chart: {
            events: {
              load: function() {
                this.tooltip.refresh(this.series[0].data[this.series[0].data.length - 1]);
              }
            },
            spacingRight:50
          },
          xAxis: {
            categories: this.xAxisValues
          },
          yAxis: {
            title: {
              text: 'Homes Sold'
            }
          },
          legend: {enabled: false},
          tooltip: {
            backgroundColor: null,
            borderWidth: 0,
            shadow: false,
            useHTML: true,
            formatter: function () {
              return "<span style='color:white'>"+this.y+"</span>";
            },
            positioner: function(boxWidth, boxHeight, point) {
              // Set up the variables
              var pointX = point.plotX,
                  pointY = point.plotY

              return {
                x: pointX + 20,
                y: pointY
              };
            }
          },
          series: [
            {
              name: 'Number of Homes Sold',
              data: this.housesSoldValues,
              color: '#999',
              marker: {
                enabled: true,
                symbol: 'circle' // Can do a custom url here if needed...
              }
            }
          ]
        },
        'supplySideChart-avgSqft':
        {
          chart: {
            events: {
              load: function() {
                this.tooltip.refresh(this.series[0].data[this.series[0].data.length - 1]);
              }
            },
            spacingRight:50
          },
          xAxis: {
            categories: this.xAxisValues
          },
          yAxis: {
            title: {
              text: 'Price per Square Foot'
            }
          },
          legend: {enabled: false},
          tooltip: {
            backgroundColor: null,
            borderWidth: 0,
            shadow: false,
            useHTML: true,
            formatter: function () {
              return "<span style='color:white'>"+_this.$options.filters.formatPrice(this.y)+"</span>";
            },
            positioner: function(boxWidth, boxHeight, point) {
              // Set up the variables
              var pointX = point.plotX,
                  pointY = point.plotY

              return {
                x: pointX + 20,
                y: pointY
              };
            }
          },
          series: [
            {
              name: 'Average Sale Price per Square Foot',
              data: this.priceSqftValues,
              color: '#999',
              marker: {
                enabled: true,
                symbol: 'circle' // Can do a custom url here if needed...
              }
            }
          ]
        },
      };
    }
  }
}

</script>
<style lang="scss" >
@import '@/styles/_custom-variables.scss';
@import '@/styles/_custom-mixins.scss';

.supplySideTrends {
  .chartwrapper {
    padding-bottom: 0;

    .nav {
      .nav-item {
        .nav-link {
          display: inline-block;
        }
      }
    }
    .svg-wrapper {
      margin-top: 0.2em;
      vertical-align: top;
    }
  }

  .supplySideChart div{
    height: 300px;
  }

  .datapoints {
    h2 {
      color: '#999';
    }
  }
  .chart-container {
    -webkit-box-shadow: 0px 0px 10px 2px rgba(125,125,125,0.75);
    -moz-box-shadow: 0px 0px 10px 2px rgba(125,125,125,0.75);
    box-shadow: 0px 0px 10px 2px rgba(125,125,125,0.75);
  }
  .highcharts-tooltip>span {
    background: rgb(100,100,100);
    color:white;
    border-radius: 50%;
    width:80px;
    height:80px;
    padding-top: 30px;
    text-align:center;
  }
}

/* Small Devices and up */
@media only screen and (min-width : 768px) {

  .supplySideTrends {
    .supplySideChart div{
      height: 350px;
    }
  }
}

</style>
