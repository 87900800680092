import Vue from 'vue'
import Vuex from 'vuex'
import listings from './modules/listings'
import properties from './modules/properties'

Vue.use(Vuex)


export default new Vuex.Store({
  modules: {
    listings,
    properties
  },
  state: {
    'activeLayer': 'all'
  },
  mutations: {
    setActiveLayer (state, value) {
      state.activeLayer = value
    }
  },
  actions: {}
})
