import axios from 'axios'

let HOST_API = process.env.VUE_APP_HOST_API
let X_API_KEY = process.env.VUE_APP_X_API_KEY

export default {
  host: HOST_API,
  headers: {
    'x-api-key': X_API_KEY,
    'content-type': 'application/json'
  },
  staticAddress: {
    address: '535 Old Buck Lane',
    city: 'Haverford',
    state: 'PA',
    zip: '19041'
  },
  /*
    getListings()
    Returns the listings data as an array
  */
  getListings (address) {
    var url = this.host + '/listings/search'
    return axios
    .get(url, {
      params: address,
      headers: this.headers
    }).then(function (response) {
      return response.data
    })
  },
  getActiveListings (address) {
    var url = this.host + '/listings/search'
    return axios
    .get(url, {
      params: address,
      headers: this.headers
    }).then(function (response) {
      return response.data
    })
  },
  getSoldListings (address) {
    var url = this.host + '/listings/search';
    return axios
    .get(url, {
      params: address,
      headers: this.headers
    }).then(function (response) {
      return response.data
    })
  },
  getPendingListings (address) {
    var url = this.host + '/listings/search';
    return axios
    .get(url, {
      params: address,
      headers: this.headers
    }).then(function (response) {
      return response.data
    })
  },
  /*
    getMarketTrends()
    Returns the market trends data as an array by lat/long
  */
  getMarketTrends(zipcode) {
    var url = this.host + '/market-trends'
    return axios
    .get(url, {
      params: {
        zipcode: zipcode
      },
      headers: this.headers
    }).then(function (response) {
      return response.data
    })
  },
}
