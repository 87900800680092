<template>
  <div id="app">
    <b-container>
      <MarketTrends />
    </b-container>
  </div>
</template>

<script>
import Vue from 'vue'
//import wrap from '@vue/web-component-wrapper'
import BootstrapVue from 'bootstrap-vue'
import 'document-register-element/build/document-register-element'
import MobileDetect from 'mobile-detect'
import HighchartsVue from 'highcharts-vue'
//import router from './router'
import store from '@/store/index'

import vueCustomElement from 'vue-custom-element'

import { ObserveVisibility } from 'vue-observe-visibility'
Vue.directive('observe-visibility', ObserveVisibility)

Vue.use(vueCustomElement)

Vue.use(BootstrapVue)

Vue.use(HighchartsVue)

Vue.config.productionTip = false

Vue.filter('formatDate', function (value) {
  if (value) {
    var date = new Date(value)
    return date.toLocaleDateString(['en-US'], {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    })
  }
  return value
})

Vue.filter('currency', function (value) {
    // Create our number formatter.
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    });
    return formatter.format(value);
})


Vue.filter('formatMonth', function (monthNum) {
  switch(monthNum) {
    case 1:  return 'January';
    case 2:  return 'February';
    case 3:  return 'March';
    case 4:  return 'April';
    case 5:  return 'May';
    case 6:  return 'June';
    case 7:  return 'July';
    case 8:  return 'August';
    case 9:  return 'September';
    case 10: return 'October';
    case 11: return 'November';
    case 12: return 'December';
    default: return '';
  }
})

Vue.filter('formatMonth3Letter', function (monthNum) {
  switch(monthNum) {
    case 1:  return 'Jan';
    case 2:  return 'Feb';
    case 3:  return 'Mar';
    case 4:  return 'Apr';
    case 5:  return 'May';
    case 6:  return 'Jun';
    case 7:  return 'Jul';
    case 8:  return 'Aug';
    case 9:  return 'Sep';
    case 10: return 'Oct';
    case 11: return 'Nov';
    case 12: return 'Dec';
    default: return '';
  }
})

// formatPrice filter usuage example: value | formatPrice
Vue.filter('formatPrice', function (value) {
  if (typeof value !== 'number') {
    return value
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: Number.isInteger(Math.round(value * 100)/100) ? 0 : 2,
    maximumFractionDigits: Number.isInteger(Math.round(value * 100)/100) ? 0 : 2
  })
  return formatter.format(value)
})

Vue.mixin({
  created () {
    let device = new MobileDetect(window.navigator.userAgent)
    this.$options.isMobile = device.mobile() !== null
  }
})

import MarketTrends from '@/components/MarketTrends'

export default {
  name: 'app',
  store,
  // router,
  props: [
    'zipcode'
  ],
  components: {
    MarketTrends
  },
  created () {
    this.$store.dispatch('properties/getMarketTrends', this.zipcode)
  }
}
</script>

<style lang="scss">
@import "@/styles/custom.scss";
@import "~bootstrap/scss/bootstrap.scss";
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
