<template>
  <div v-observe-visibility="{
    callback: visibilityChanged,
    once: true,
  }">
    <highcharts v-if="show" :options="options"></highcharts>
  </div>
</template>

<script>
export default {
  data () {
    return {
      show: false
    }
  },
  props: {
    options: {
      required: true,
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    visibilityChanged (isVisible, entry) {
      if(entry.isIntersecting) {
        this.show = true
      }
    }
  }
}
</script>

<style lang="scss">

</style>
