<template>
  <b-row class="section" :class="['section-' + pageTheme, {'mx-xl-auto': isGeneric && !isPDF}]">
    <b-col v-if="dividerline" cols="24" class="divider-line-col">
      <div class="divider-line"></div>
    </b-col>
    <b-col cols="24" class="main-section-col" :class="[colClass, {'section-content row no-gutters': hasResponsiveTitle}]">

        <div v-if="(title !== '')" class="title-head" :class="responsiveTitleClass">
          <b-row class="title-head-row" :class="{'mx-2 mx-sm-4': isGeneric && !this.isPDF}">
            <b-col v-if="!!this.$slots.icon && isGeneric" cols="auto" class="d-inline-block align-top my-sm-1 svg-wrapper" :class="this.iconClass">
              <slot name="icon"></slot>
            </b-col>
            <b-col :class="{'px-sm-3 px-2': isGeneric, 'pl-0': isSage}">
              <b-row>
                <b-col cols="24" class="d-inline-block text-left title-content" :class="{'col-sm-12 pt-3 py-sm-4': isGeneric && !this.isPDF}">
                  <h2 class="title" v-html="title"></h2>
                </b-col>
                <b-col cols="24" class="d-inline-block text-left title-content" :class="{'text-sm-right col-sm py-sm-3': isGeneric && !this.isPDF}">
                  <p v-if="(secondaryTitle !== '')" class="secondary-title" v-html="secondaryTitle"></p>
                  <p v-if="(subtitle !== '')" class="subtitle" :class="{'ml-auto pt-2': !this.isPDF, 'pb-3 py-sm-3': !this.isPDF && isGeneric}" v-html="subtitle"></p>
                  <div v-if="!!this.$slots.editButton" class="edit-button">
                    <slot name="editButton"></slot>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>

        <div class="section-content" v-if="!!this.$slots.content && !hasResponsiveTitle">
          <slot name="content"></slot>
        </div>
        <slot v-if="!!this.$slots.content && hasResponsiveTitle" name="content"></slot>

    </b-col>
  </b-row>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    secondaryTitle: {
      type: String,
      default: ''
    },
    colClass: {
      type: String,
      default: ''
    },
    iconClass: {
      type: String,
      default: 'my-2'
    },
    dividerline: {
      type: Boolean,
      default: false
    },
    themeOverride: {
      type: String,
      default: 'berkshire'
    },
    responsiveTitle: {
      type: String,
      default: undefined
    }
  },
  inject: {
    'isPDF': { default: false }
  },
  computed: {
    pageTheme () {
      return (this.themeOverride !== '') ?
        this.themeOverride : 'berkshire';
        //this.$store.state.theme;
    },
    isGeneric () {
      return this.pageTheme === 'generic';
    },
    isSage() {
      return !this.isGeneric;
    },
    hasResponsiveTitle () {
      return (this.responsiveTitle !== undefined);
    },
    responsiveTitleClass () {
      if(this.responsiveTitle === undefined) {
        return '';
      }
      else if(this.responsiveTitle === '') {
        return 'col-md-8'; //default
      }
      else {
        return this.responsiveTitle;
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/_custom-variables.scss';
@import '@/styles/_custom-mixins.scss';

//.section styles will apply to the section regardless of theme
.section {
  padding-top:30px;
  position:relative;

  .title-content .title {
    font-family: $primary-font;
    font-size: 32px;
    margin-bottom: 20px;
  }

  .title-content .subtitle {
    font-family: $secondary-font;
    font-size: 14px;
    line-height: 24px;
  }
}

// for generic theme, including PDF view
.section-generic {
  padding-top: 10px;
  margin-left: -10px;
  margin-right: -10px;

  .main-section-col
  {
    padding-left: 0;
    padding-right: 0;
  }

  .svg-wrapper {
    padding-top: 21px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  .title-content .title {
    font-family: $primary-font;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  .title-content .subtitle {
    font-family: $secondary-font;
    font-size: 14px;
    line-height: 24px;
  }
}

//styles explicitly NOT applied to PDF view
#app > div:not(#BMA_PDF){
  // only for generic theme
  .section-generic {
    box-shadow: 0px 0px 40px rgba(85, 124, 149, .1);
    max-width: 1440px;
    border-radius: 4px;
    margin-bottom: 30px;
    padding-top: 0;

    .title-head {
      min-height: 92px;
    }

    .title-head,
    .section-content > div:not(:last-child) {
      border-bottom: 1px solid #DDDDDD;
    }

    .title-content .title {
      margin-top: 5px;
      margin-bottom: 0;
    }

    .title-content .subtitle {
      line-height: 18px;
      margin-bottom: 0;
      @include vertical-align();
    }
  }
}

// only for Berkshire theme
.section-berkshire {
  padding-top: 0;

  .title-content .title {
    font-weight: normal;
    line-height: 40px;
    font-size: 40px;
    margin-bottom: 8px;
  }

  .divider-line-col {
    padding-top: 50px;
    .divider-line {
      @include bh-section-bar();

    }
  }

}

// ONLY FOR MOBILE
@media only screen and (max-width : 767px) {
  .section-generic {
    .title-content .title {
      font-size: 28px;
      line-height: 28px;
    }
  }

  .section-berkshire {
    .secondary-title {
      font-size: 30px;
      line-height: 30px;
      margin-top: 8px;
    }

    .subtitle,
    .title-content .subtitle {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

// BIGGER THAN MOBILE
@media only screen and (min-width : 768px) {
  .section-generic {
    .svg-wrapper {
      padding-top: 29px;
    }
  }
}

</style>
