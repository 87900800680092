import api from '@/api/mock'

export default {
  namespaced: true,
  state: {
    marketTrends: [],
    /* Loading Area */
    loadingMarketTrends: true
  },
  getters: {
    marketTrends (state, getters) {
      return state.marketTrends
    },
    marketTrendsMonths (state, getters) {
      const months = []
      state.marketTrends.forEach(function(dataPoint) {
        months.push(dataPoint.month)
      })
      return months
    },
    medianPriceValues (state, getters) {
      const medianPrices = []
      state.marketTrends.forEach(function(dataPoint) {
        medianPrices.push(dataPoint.median_sale_price)
      })
      return medianPrices
    },
    housesSoldValues (state, getters) {
      const housesSold = []
      state.marketTrends.forEach(function(dataPoint) {
        housesSold.push(dataPoint.homes_sold)
      })
      return housesSold
    },
    priceSqftValues (state, getters) {
      const priceSqft = []
      state.marketTrends.forEach(function(dataPoint) {
        priceSqft.push(dataPoint.avg_price_per_sqft)
      })
      return priceSqft
    }
  },
  mutations: {
    setMarketTrends (state, value) {
      state.marketTrends = value
    },

    /* Loading Mutations */
    loadingMarketTrends(state, value) {
      state.loadingMarketTrends = value
    }
  },
  actions: {
    getMarketTrends ({ commit }, address) {
      commit('loadingMarketTrends', true)
      return api
        .getMarketTrends(address)
        .then(function (marketTrends) {
          marketTrends.sort(function (a, b) {
            var aYear = a.year;
            var bYear = b.year;
            var aMonth = a.month;
            var bMonth = b.month;

            if(aYear == bYear)
            {
              return (aMonth < bMonth) ? -1 : (aMonth > bMonth) ? 1 : 0;
            }
            else
            {
              return (aYear < bYear) ? -1 : 1;
            }
          });
          console.log("marketTrends")
          console.log(marketTrends)
          commit('setMarketTrends', marketTrends)
          commit('loadingMarketTrends', false)
        })
    }
  }
}
