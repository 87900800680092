import api from '@/api/mock'

export default {
  namespaced: true,
  state: {
    listings: [],
    activeListings: [],
    soldListings: [],
    pendingListings: [],
  },
  getters: {
    listings (state, getters) {
      return state.listings
    },
    activeListings (state, getters) {
      return state.activeListings
    },
    soldListings (state, getters) {
      return state.soldListings
    },
    pendingListings (state, getters) {
      return state.pendingListings
    }
  },
  mutations: {
    setListings (state, value) {
      state.listings = value
    },
    setActiveListings (state, value) {
      state.activeListings = value
    },
    setSoldListings(state, value){
      state.soldListings = value
    },
    setPendingListings(state, value){
      state.pendingListings = value
    }
  },
  actions: {
    getListings ({ commit }, address) {
      return api
        .getListings(address)
        .then(function (listings) {
          commit('setListings', listings)
        })
    },
    getActiveListings ({ commit }, address) {
      return api
        .getActiveListings(address)
        .then(function (listings) {
          commit('setActiveListings', listings)
        })
    },
    getSoldListings ({ commit }, address) {
      return api
        .getSoldListings(address)
        .then(function (soldListings) {
          commit('setSoldListings', soldListings)
        })
    },
    getPendingListings ({ commit }, address) {
      return api
        .getPendingListings(address)
        .then(function (pendingListings) {
          commit('setPendingListings', pendingListings)
        })
    }
  }
}
