<template>
  <div>
    <highcharts :options="options" ref="theChart"></highcharts>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: Object.assign({
        chart: {
          type: this.type
        },
        credits: {
          enabled: false
        },
        title: {
          text: this.title,
          margin: 30
        },
        subtitle: {
          text: this.subtitle,
          align: 'left',
          useHTML: true
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false,
              symbol: 'circle' // Sets both markers to a circle here...
            }
          },
          column: {
            dataLabels: {
              enabled: false
            },
            enableMouseTracking: false
          }
        },
        legend: {
          enabled: true,
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom'
        },
        yAxis: {
          min: 0
          // categories: [0, 1, 2, 3, 4]
        },
        xAxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        series: [{
          name: 'Your Listing',
          data: [],
          id: 'your-listing',
          showInLegend: true,
          marker: {
            enabled: true,
            symbol: 'circle' // Can do a custom url here if needed...
          }
        },
        {
          linkedTo: 'your-listing',
          color: '#004A98',
          data: [3, 4, 2, 2.5, 1.3, 2.9, 2.9, 3.8]
        },
        {
          name: '14 day Moving Average',
          data: [],
          id: 'moving-avg',
          showInLegend: true,
          marker: {
            enabled: true,
            symbol: 'circle' // Can do a custom url here if needed...
          }
        }, {
          linkedTo: 'moving-avg',
          color: '#E9E9E9',
          data: [1.25, 2.2, 2.2, 3.8, 2.5, 3.25, 1.3, 2.2],
        }]
      }, this.overrides)
    }
  },
  props: {
    title: {
      type: String,
      default: 'Month Chart'
    },
    subtitle: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'line'
    },
    overrides: {
      required: false,
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    forceReflow: function () {
      let x = this.$refs.theChart.chart;
      x.reflow();
    }
  }
}

</script>

<style lang="scss">

</style>
