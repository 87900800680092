<template>
  <div>
    <content-loader
  		:height="160"
  		:width="400"
  		:speed="2"
  		primaryColor="#f3f3f3"
  		secondaryColor="#ecebeb"
  	>
  		<rect x="29.31" y="8.61" rx="0" ry="0" width="7" height="139" />
  		<rect x="29.31" y="141.61" rx="0" ry="0" width="355" height="6" />
  		<rect x="68.31" y="92.61" rx="0" ry="0" width="24" height="49" />
  		<rect x="129.31" y="56.61" rx="0" ry="0" width="24" height="87" />
  		<rect x="189.31" y="82.61" rx="0" ry="0" width="24" height="60" />
  		<rect x="250.31" y="67.61" rx="0" ry="0" width="24" height="77" />
  		<rect x="308.31" y="90.61" rx="0" ry="0" width="24" height="51" />
  	</content-loader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'graph-loader',
  components: {
    ContentLoader
  }
}
</script>
